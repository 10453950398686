import { Search } from '@mui/icons-material'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import {
  BaseDateSelect,
  HandleBaseSelectInput,
} from 'src/component/base-component/base-date-select'
import { BaseDropDownInput } from 'src/component/base-component/base-dropdown'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { BaseInputSingleSelect } from 'src/component/base-component/base-input-single-select'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { LedgerAccountType } from 'src/page/classifier/useClassifier'
import { TCompanyAccountEntryCreate } from 'src/service/service-company'
import {
  allSpecialFieldsList,
  SPECIAL_COMPANY,
  specialCompaniesList,
  specialFieldsByCompany,
} from 'src/utils/specialFieldsByCompany'

const HistoricVariables = [
  {
    label: 'Data',
    value: 'data',
  },
  {
    label: 'Descrição',
    value: 'descricao',
  },
  {
    label: 'Valor',
    value: 'valor',
  },
  {
    label: 'Débito',
    value: 'debito',
  },
  {
    label: 'Crédito',
    value: 'credito',
  },
]

export const ModalClassifierPaymentEntry: React.FC<{
  handle: (data: TCompanyAccountEntryCreate[]) => Promise<void>
  companyId: string
  type: 'transfer' | 'made' | 'provision' | 'conciliator'
  accountsOptions: {
    value: string | number
    label: string
    icon?: React.ReactNode
  }[]
  financialCategories: string[]
  creditAccounts: string[]
  debitAccounts: string[]
  defaultDebitAccount?: string
  defaultCreditAccount?: string
  defaultValue?: string
  defaultHistoric?: string
  ledgerAccount?: LedgerAccountType
}> = ({
  handle,
  accountsOptions,
  type,
  companyId,
  defaultHistoric,
  defaultValue,
  ledgerAccount,
}) => {
  const { HideModal } = ModalFullContext()
  const isSpecialCompany = specialCompaniesList.includes(
    companyId as SPECIAL_COMPANY,
  )
  const [bankAccount, setBankAccount] = useState('')
  const [bankAccountTo, setBankAccountTo] = useState('')
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const [date, setDate] = useState<Date | undefined>(undefined)

  const DateInitialRef = useRef<HandleBaseSelectInput>(null)
  const [value, setValue] = useState(defaultValue ?? '')
  const [description, setDescription] = useState('')
  const [historic, setHistoric] = useState(defaultHistoric ?? '')
  const [client, setClient] = useState('')

  const handleSelectDate = (e: Date | undefined) => {
    setDate(e)
  }

  const handleCreate = async () => {
    if (!date || !value) return
    setIsCreating(true)

    try {
      const amount = Math.abs(Number(value?.replace(/[^\d-]/g, '')) / 100)
      const validatedNumber = Number(value?.replace(/[^\d-]/g, '')) / 100
      const localDate = new Date(date)
      const data = [
        {
          date: localDate.toUTCString(),
          bankTransferDate: localDate.toUTCString(),
          value: amount.toString(),
          method:
            validatedNumber <= 0 || type === 'transfer' ? 'DEBITO' : 'CREDITO',
          description,
          historic,
          vendor: client,
          bankAccountFromId: bankAccount,
          bankTransferMethod:
            validatedNumber <= 0 || type === 'transfer' ? 'DEBITO' : 'CREDITO',
          ...dynamicFields,
        },
      ]

      if (type === 'transfer') {
        data.push({
          date: localDate.toUTCString(),
          bankTransferDate: localDate.toUTCString(),
          value: amount.toString(),
          method: 'CREDITO',
          description,
          historic,
          vendor: client,
          bankAccountFromId: bankAccountTo,
          bankTransferMethod: 'CREDITO',
        })
      }

      if (handle) {
        await handle(data)
      }
    } catch (error) {
      console.error('Erro ao criar lançamentos:', error)
    } finally {
      setIsCreating(false)
    }
  }
  const [dynamicFields, setDynamicFields] = useState<Record<string, any>>({})

  const updateFieldValue = (field: string, value: any) => {
    setDynamicFields((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const fields = () => {
    if (ledgerAccount) {
      const fieldsDefault = Object.keys(ledgerAccount).filter(
        (field) => !allSpecialFieldsList.map((e) => e.value).includes(field),
      )
      if (isSpecialCompany) {
        const specialFields =
          specialFieldsByCompany[companyId as SPECIAL_COMPANY]
        const specialFieldsLedger = Object.keys(ledgerAccount).filter((field) =>
          specialFields.map((e) => e.value).includes(field),
        )
        return [...fieldsDefault, ...specialFieldsLedger]
      }

      return fieldsDefault
    }
  }

  useEffect(() => {
    const initialFields = fields()?.reduce(
      (acc, field) => {
        acc[field] = ''
        return acc
      },
      {} as Record<string, any>,
    )
    setDynamicFields(initialFields || {})
  }, [ledgerAccount])

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'max-content max-content',
        maxHeight: '80vh',
        overflowY: 'auto',
        padding: '0 20px',
      }}
    >
      <Box
        sx={{
          width: '600px',
          borderRadius: '16px',
        }}
      >
        {/* Conta Origem */}
        {(type === 'transfer' || type === 'made' || type === 'conciliator') && (
          <BaseInputSingleSelect
            options={accountsOptions}
            onSelect={setBankAccount}
            placeholder={type === 'made' ? 'Conta bancária' : 'Conta Origem'}
            value={bankAccount}
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          gap="8px"
          flexWrap="wrap"
        >
          {fields()?.map((field, index) => {
            const ledgerField = (ledgerAccount as LedgerAccountType)[field]
            return (
              <BaseInputAutocomplete
                key={index}
                companyId={String(companyId)}
                options={ledgerField.ledger}
                setState={(value) => updateFieldValue(field, value)}
                error=""
                label={ledgerField.label}
                value={dynamicFields[field] || ''}
                inputVariant="bordered"
                labelBackground="#F4F8FA"
                containerStyle={{ width: '100%', flex: '1', minWidth: '220px' }}
              />
            )
          })}
        </Stack>

        {type === 'transfer' && (
          <Box
            width="100%"
            borderBottom="dashed 1px #777C84"
            marginTop="20px"
          />
        )}

        {/* Conta Destino */}
        {type === 'transfer' && (
          <>
            <BaseInputSingleSelect
              options={accountsOptions}
              onSelect={setBankAccountTo}
              placeholder="Conta Destino"
              value={bankAccountTo}
            />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
              gap="8px"
              flexWrap="wrap"
            >
              {fields()?.map((field, index) => {
                const ledgerField = (ledgerAccount as LedgerAccountType)[field]
                return (
                  <BaseInputAutocomplete
                    key={index}
                    companyId={String(companyId)}
                    options={ledgerField.ledger}
                    setState={(value) => updateFieldValue(field, value)}
                    error=""
                    label={ledgerField.label}
                    value=""
                    inputVariant="bordered"
                    labelBackground="#F4F8FA"
                    containerStyle={{
                      width: '100%',
                      flex: '1',
                      minWidth: '220px',
                    }}
                  />
                )
              })}
            </Stack>
          </>
        )}

        {type === 'transfer' && (
          <Box
            width="100%"
            borderBottom="dashed 1px #777C84"
            marginTop="20px"
            marginBottom="20px"
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap="18px"
          marginTop="18px"
        >
          <BaseDateSelect
            title="Data"
            onSelect={(e) => handleSelectDate(e)}
            ref={DateInitialRef}
            containerStyle={{ width: '50%' }}
            buttonStyle={{ width: '100%' }}
            useCalendarPosition={false}
          />

          <BaseInputMoney
            label="Valor"
            type="text"
            fullWidth={true}
            value={value}
            setState={(e) => setValue(e)}
            error=""
            containerStyle={{ width: '50%' }}
          />
        </Stack>

        <Stack direction="column" gap="18px" marginY="18px">
          <BaseInputAutocomplete
            companyId={companyId}
            options={[]}
            error=""
            label="Clientes e fornecedores"
            fullWidth
            setState={(e) => setClient(e)}
            value={client}
            containerStyle={{ width: '100%' }}
            iconEnd={<Search />}
            inputVariant="bordered"
          />

          {(type === 'transfer' || type === 'made') && (
            <BaseInput
              label="Descrição"
              fullWidth
              setState={(e) => setDescription(e)}
              error=""
              value={description}
              containerStyle={{ width: '100%' }}
            />
          )}

          <BaseDropDownInput
            options={HistoricVariables}
            buttonLabel="Histórico"
            showInternalLabel
            setState={(e) => setHistoric(e?.join('|'))}
            error=""
            variant="outlined"
            value={historic ? historic?.split('|') : undefined}
          />
        </Stack>
      </Box>

      {/* Footer */}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content max-content',
          gap: '14px',
          justifySelf: 'end',
          padding: '0 4px',
        }}
      >
        <Button
          variant="outlined"
          onClick={HideModal}
          sx={{ width: '104px', height: '40px' }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={isCreating}
          onClick={() => {
            handleCreate()
          }}
          sx={{ width: '175px', height: '40px' }}
        >
          {isCreating ? (
            <CircularProgress sx={{ height: 24, width: 24 }} />
          ) : (
            'Criar lançamento'
          )}
        </Button>
      </Box>
    </Box>
  )
}
