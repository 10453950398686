import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { Item } from 'pluggy-js'
import { useEffect, useState } from 'react'
import { PluggyConnect } from 'react-pluggy-connect'
import { useNavigate, useParams } from 'react-router-dom'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { BaseSelectInput } from 'src/component/base-component/base-select-input'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { DefaultHeader } from 'src/component/header/header'
import {
  IOptionType,
  OpenFinanceAccountSelectModal,
} from 'src/component/modal/modal-open-finance-account-select'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { SimpleTextModal } from 'src/component/modal/simple-modal-text'
import {
  BankAccountPersonTypeEnum,
  BankAccountTypeEnum,
  IBankAccount,
  IBankAccountOpenFinanceInitialFlowResponse,
} from 'src/service/service-account'
import { IBank } from 'src/service/service-bank'
import { CreateForm } from 'src/shared/util/form/form-util'
import { CreateAccountStepTwopluggyFinalFlowUseCase } from 'src/usecase/bank-account/usecase-account-create-step-two-pluggy-final-flow'
import { CreateAccountStepTwopluggyInititalFlowUseCase } from 'src/usecase/bank-account/usecase-account-create-step-two-pluggy-initial-flow'
import { ListCompanyAccountUseCase } from 'src/usecase/bank-account/usecase-account-find-one'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'
import { UpdateAccountOpenFinanceConnectUseCase } from 'src/usecase/bank-account/usecase-account-open-finance-connect'
import { UpdateAccountOpenFinanceDisconnectUseCase } from 'src/usecase/bank-account/usecase-account-open-finance-disconnect'
import { UpdateAccountUseCase } from 'src/usecase/bank-account/usecase-account-update'
import { ListBanksUseCase } from 'src/usecase/bank/usecase-bank-list'
import BlueHeader from '../../assets/background/header-blue.png'
import { maskBankAccount, maskBankAgency } from 'src/utils/stringFormatterUtils'
import { BaseTooltip } from 'src/component/base-component/base-tooltip'
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete'
import { UserFullContext } from 'src/context/context-user'

interface UpdateAccountPageProps {
  getAccountCase: ListCompanyAccountUseCase
  updateUseCase: UpdateAccountUseCase
  listBanksUseCase: ListBanksUseCase
  disconnectOpenFinanceUseCase: UpdateAccountOpenFinanceDisconnectUseCase
  connectOpenFinanceUseCase: UpdateAccountOpenFinanceConnectUseCase
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase
  openFinanceFinalFlowUseCase: CreateAccountStepTwopluggyFinalFlowUseCase
  openFinanceInitialFlowUseCase: CreateAccountStepTwopluggyInititalFlowUseCase
}

export function UpdateSystemicAccountPage(props: UpdateAccountPageProps) {
  const { companyId, accountId } = useParams()
  const navigate = useNavigate()
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const { GetCompanyData } = UserFullContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingCC, setIsLoadingCC] = useState<boolean>(false)
  const [accountType, setAccountType] = useState<BankAccountTypeEnum>()
  const [openFinance, setOpenFinance] = useState<boolean>(false)
  const [banks, setBanks] = useState<IBank[]>([])
  const [transactionCommencementDate, setTransactionCommencementDate] =
    useState<string>('')
  const [currency, setCurrency] = useState<string>('')
  const [amount, setAmount] = useState<string>('0')
  const [ledgerAccountBalance, setLedgerAccountBalance] = useState<string>('0')
  const [ledgerAccountStartDate, setLedgerAccountStartDate] =
    useState<string>('')
  const [initialLedgerAccountBalance, setInitialLedgerAccountBalance] =
    useState<string>('0')
  const [initialAmount, setInitialAmount] = useState<string>('0')
  const [currencies, setCurrencies] = useState<
    {
      symbol: string
      name: string
    }[]
  >([])
  const [openFinanceInitialFlowData, setOpenFinanceInitialFlowData] =
    useState<null | {
      externalUserId: string
      consentedAccountId: string
      accessToken: string
    }>(null)
  const [newAccount, setNewAccount] = useState<Partial<IBankAccount> | null>(
    null,
  )
  const [ledgerAccounts, setLedgerAccounts] = useState<string[]>([])

  const loadBanks = async () => {
    const banksResult = await props.listBanksUseCase.handle()
    if (banksResult.isFailure) {
      ShowToast('error', 'Erro ao carregar a lista de bancos! Tente novamente.')
      return
    }
    const banks = banksResult.getValue()
    if (banks?.length === 0 || !banks) {
      ShowToast('error', 'Erro ao carregar a lista de bancos! Tente novamente.')
      return
    }
    setBanks(banks)
  }

  const updateForm = CreateForm({
    fields: [
      'name',
      'personType',
      'bankNumber',
      'agencyNumber',
      'accountNumber',
      'ledgerAccount',
    ],
    validation: props.updateUseCase.validation,
  })

  const handleGetData = async () => {
    setIsLoading(true)

    const accountResult = await props.getAccountCase.handle({
      companyId: companyId as string,
      accountId: accountId as string,
    })
    setIsLoading(false)

    if (accountResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar a contas bancária. Tente novamente mais tarde.',
      )
    }

    const accountData = accountResult.getValue() as IBankAccount
    updateForm.fields.name.setState(() => accountData.name)
    updateForm.fields.personType.setState(() => accountData.personType)
    updateForm.fields.bankNumber.setState(() =>
      Number(accountData.bankNumber).toString(),
    )
    updateForm.fields.agencyNumber.setState(() => accountData.agencyNumber)
    updateForm.fields.accountNumber.setState(() => accountData.accountNumber)
    updateForm.fields.ledgerAccount.setState(() => accountData.ledgerAccount)

    setLedgerAccountBalance(
      accountData?.ledgerAccountBalance?.toString()?.replace('.', ','),
    )
    setInitialLedgerAccountBalance(
      accountData?.ledgerAccountBalance?.toString(),
    )
    if (accountData.ledgerAccountStartDate) {
      setLedgerAccountStartDate(
        format(
          parseISO(accountData.ledgerAccountStartDate.toString()),
          'yyyy-MM-dd',
        ),
      )
    }

    if (accountData.transactionCommencementDate) {
      const utcDate = parseISO(
        accountData.transactionCommencementDate.toString(),
      )
      const localDate = new Date(
        utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
      )
      setTransactionCommencementDate(format(localDate, 'yyyy-MM-dd'))
    }

    // Se for OF, balanço atual, senão, balanço inicial
    setAmount(
      accountData.openFinance
        ? accountData.balance
        : accountData.initialBalance,
    )
    setInitialAmount(accountData.balance)
    setCurrency('R$')
    setAccountType(accountData.accountType)
    setOpenFinance(accountData.openFinance)
  }

  const handleDisconnectUsecase = async () => {
    try {
      setIsLoading(true)
      const disconnectResult = await props.disconnectOpenFinanceUseCase.handle({
        companyId: companyId as string,
        accountId: accountId as string,
      })
      setIsLoading(false)

      if (disconnectResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao desconectar da Open Finance. Tente novamente mais tarde.',
        )
        HideModal()
      }

      await handleGetData()
      HideModal()
      ShowToast('success', 'Sucesso!.')
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao desconectar da Open Finance. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const loadCurrencies = async () => {
    setCurrencies([
      {
        symbol: 'R$',
        name: 'R$ - Reais (BRL)',
      },
      {
        symbol: '$',
        name: '$ - Dollar (USD)',
      },
      {
        symbol: '€',
        name: '€ - Euro (EUR)',
      },
      {
        symbol: '¥',
        name: '¥ - Japanese Yen (JPY)',
      },
      {
        symbol: '£',
        name: '£ - British Pound (GBP)',
      },
      {
        symbol: 'CHF',
        name: 'CHF - Swiss Franc (CHF)',
      },
      {
        symbol: 'C$',
        name: 'C$ - Canadian Dollar (CAD)',
      },
      {
        symbol: '₹',
        name: '₹ - Indian Rupee (INR)',
      },
      {
        symbol: '₽',
        name: '₽ - Russian Ruble (RUB)',
      },
    ])
  }

  useEffect(() => {
    loadBanks()
    loadCurrencies()
    handleGetData()
  }, [props])

  const handleUpdateAcountUsecase = async () => {
    const bank = banks.find(
      (f) => f.code === updateForm.fields.bankNumber.value,
    )?.name

    let balance: number | undefined
    if (
      Number(initialAmount?.replace(/[^\d-]/g, '')) / 100 !==
      Number(amount?.replace(/[^\d-]/g, '')) / 100
    ) {
      balance = Number(amount?.replace(/[^\d-]/g, '')) / 100
    }

    let ledgerAccountFormattedBalance: number | undefined
    let ledgerAccountFormattedDate: Date | string | undefined
    let transactionCommencementDateFormatted: Date | string | undefined

    if (ledgerAccountBalance !== '') {
      ledgerAccountFormattedBalance = Number(
        ledgerAccountBalance
          ?.replace('R$', '')
          .replace('.', '')
          .replace(',', '.')
          .trim(),
      )
    }

    if (ledgerAccountStartDate) {
      const tempLedgerAccountStartDate = new Date(ledgerAccountStartDate)
      tempLedgerAccountStartDate.setUTCHours(12, 0, 0, 0)
      ledgerAccountFormattedDate = tempLedgerAccountStartDate.toUTCString()
    }

    if (transactionCommencementDate) {
      const tempCommencementDate = new Date(transactionCommencementDate)
      tempCommencementDate.setUTCHours(12, 0, 0, 0)
      transactionCommencementDateFormatted = tempCommencementDate.toUTCString()
    }
    const data = {
      id: accountId as string,
      companyId: companyId as string,
      name: updateForm.fields.name.value,
      accountType: accountType as BankAccountTypeEnum,
      personType: updateForm.fields.personType.value,
      bankNumber: updateForm.fields.bankNumber.value,
      bank: bank as string,
      agencyNumber: updateForm.fields.agencyNumber.value,
      accountNumber: updateForm.fields.accountNumber.value,
      ledgerAccount: updateForm.fields.ledgerAccount.value,
      ledgerAccountBalance: ledgerAccountFormattedBalance,
      ledgerAccountStartDate: ledgerAccountFormattedDate,
      transactionCommencementDate: transactionCommencementDateFormatted,
      // balance,
    }

    const updateData = await props.updateUseCase.handle(data)

    if (updateData.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a atualização. Tente novamente mais tarde.',
      )
    } else {
      ShowToast('success', 'Dados atualizados com sucesso.')
    }
  }

  const handleValidate = async () => {
    try {
      const validationResult = await updateForm.validate()

      if (validationResult.isFailure) {
        ShowToast('error', 'Verifique todos os campos')
        return
      }

      handleUpdateAcountUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a atualização. Tente novamente mais tarde.',
      )
    }
  }

  const handleCancel = () => {
    navigate(`/company/${companyId}/account/list`)
  }

  const handleErrorFlow = (error: {
    message: string
    data?: {
      item: Item
    }
  }) => {
    console.log(error)
    ShowToast(
      'error',
      'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
    )
  }

  const handleFinalFlow = async (
    data: IOptionType | null,
    bankLogo: string,
    consentedAccountId: string,
  ) => {
    if (!data) {
      HideModal()
      return
    }

    if (
      data.accountNumber !== updateForm.fields.accountNumber.value ||
      data.agencyNumber !== updateForm.fields.agencyNumber.value
    ) {
      return ShowToast(
        'error',
        'A conta OpenFinance selecionada é diferente da conta sistêmica atual.',
      )
    }

    HideModal()
    setIsLoading(true)
    const connectResult = await props.connectOpenFinanceUseCase.handle({
      companyId: companyId as string,
      accountId: accountId as string,
      editData: {
        bankLogo,
        consentedAccountId,
      },
    })

    if (connectResult.isFailure) {
      return ShowToast(
        'error',
        'Houve um problema durante a sincronização. Tente novamente mais tarde.',
      )
    }
    ShowToast('success', 'Conta sincronizada com sucesso.')
    await handleGetData()
    setIsLoading(false)
  }

  const handleOpenFinanceFinalFlowUsecase = async ({
    externalUserId,
    consentedAccountId,
    item,
  }: {
    externalUserId: string
    consentedAccountId: string
    item: Item
  }) => {
    try {
      setIsLoading(true)

      const data = {
        companyId: companyId as string,
        externalUserId,
        consentedAccountId,
        pluggyCallback: {
          event: 'item/created',
          itemId: item.id,
        },
      }

      const result = await props.openFinanceFinalFlowUseCase.handle(data)
      setIsLoading(false)

      if (result.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
        )
        HideModal()
      }

      const finalFlowResponse = result.getValue()

      if (
        finalFlowResponse?.accounts &&
        finalFlowResponse?.accounts.data.length > 0
      ) {
        setNewAccount((prevUser) => ({
          ...prevUser,
          consentedAccountId,
        }))

        ShowModal({
          content: (
            <OpenFinanceAccountSelectModal
              data={finalFlowResponse?.accounts.data.map((account) => {
                return {
                  id: account.accountId,
                  holderId: finalFlowResponse.holder.id,
                  bankNumber: account.compeCode,
                  type: account.type,
                  companyCnpj: account.companyCnpj,
                  agencyNumber: account.branchCode,
                  accountNumber: account.number,
                  bankLogo: item.connector.institutionUrl,
                  bankName: item.connector.name,
                  creditCard:
                    (account.type as unknown as string) === 'CARTAO_CREDITO'
                      ? {
                          brandName: account.brandName,
                          number: account.number,
                        }
                      : undefined,
                }
              })}
              handle={(e) =>
                handleFinalFlow(
                  e,
                  item.connector.institutionUrl,
                  consentedAccountId,
                )
              }
            />
          ),
          title: 'Selecione a conta de gostaria de criar',
          closeButton: false,
          style: { maxWidth: '300px' },
        })
      }
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const handleInitialFlow = async (data: { item: Item }) => {
    try {
      if (!openFinanceInitialFlowData) {
        return
      }

      handleOpenFinanceFinalFlowUsecase({
        externalUserId: openFinanceInitialFlowData.externalUserId,
        consentedAccountId: openFinanceInitialFlowData.consentedAccountId,
        item: data.item,
      })
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const handleOpenFinanceInitialFlowUsecase = async () => {
    try {
      setIsLoading(true)
      const accountsResult = await props.openFinanceInitialFlowUseCase.handle()
      setIsLoading(false)

      if (accountsResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
        )
        HideModal()
      }

      const initialFlowResponse =
        accountsResult.getValue() as IBankAccountOpenFinanceInitialFlowResponse

      HideModal()

      if (
        initialFlowResponse.consentedAccountId &&
        initialFlowResponse.externalUserId &&
        initialFlowResponse.accessToken
      ) {
        return {
          externalUserId: initialFlowResponse.externalUserId,
          consentedAccountId: initialFlowResponse.consentedAccountId,
          accessToken: initialFlowResponse.accessToken,
        }
      } else {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
        )
      }
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar a conexão com a pluggy. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const handleConnectionFlow = async () => {
    try {
      const handleOpenFinanceInitialFlow =
        await handleOpenFinanceInitialFlowUsecase()

      if (!handleOpenFinanceInitialFlow) {
        setOpenFinanceInitialFlowData(null)
        return
      }

      setOpenFinanceInitialFlowData(handleOpenFinanceInitialFlow)
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro durante o processo. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const handleDisconnectFlow = async () => {
    try {
      await handleDisconnectUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro durante o processo. Tente novamente mais tarde.',
      )
      HideModal()
    }
  }

  const handleOpenPluggyConsentModal = () => {
    if (!openFinance) {
      ShowModal({
        content: (
          <SimpleTextModal
            firstButtonText="Cancelar"
            secondButtonText="Conectar"
            firstButtonAction={() => HideModal()}
            secondButtonAction={() => handleConnectionFlow()}
            text="Deseja realmente conectar sua conta 
            ao Open Finance?."
            height={150}
            width={250}
          />
        ),
        title: 'Conectar Open Finance?',
        closeButton: false,
      })
    } else {
      ShowModal({
        content: (
          <SimpleTextModal
            firstButtonText="Cancelar"
            secondButtonText="Desconectar"
            firstButtonAction={() => HideModal()}
            secondButtonAction={() => handleDisconnectFlow()}
            text="Deseja realmente desconectar sua conta 
            do Open Finance? Lembre-se que o Open Finance 
            garante a segurança e privacidade de sua conta."
            height={180}
            width={280}
          />
        ),
        title: 'Desconectar Open Finance?',
        closeButton: false,
      })
    }
  }

  const handleBlurAgency = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    updateForm.fields.agencyNumber.setState(
      maskBankAgency(updateForm.fields.agencyNumber.value),
    )
  }

  const handleBlurAcountNumber = async (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    updateForm.fields.accountNumber.setState(
      maskBankAccount(updateForm.fields.accountNumber.value),
    )
  }

  const handleGeLedgerAccounts = async () => {
    try {
      setIsLoadingCC(true)
      const ledgerAccountResult = await props.listLedgerAccountsUseCase.handle({
        companyId: companyId as string,
        query: '',
      })

      if (ledgerAccountResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar as contas contábeis. Tente novamente mais tarde.',
        )
        return
      }

      const ledgerAccountData = ledgerAccountResult.getValue()
      const combined = [
        ...new Set([
          ...(ledgerAccountData?.credtis?.ledger ?? []),
          ...(ledgerAccountData?.debits?.ledger ?? []),
        ]),
      ]
      setLedgerAccounts(combined)
      setIsLoadingCC(false)
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas contábeis. Tente novamente mais tarde.',
      )
      console.error(error)
    }
  }

  useEffect(() => {
    handleGeLedgerAccounts()
  }, [])

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          { title: 'Home', navigateTo: `/company/${companyId}` },
          {
            title: 'Contas sistêmicas',
            navigateTo: `/company/${companyId}/account/list`,
          },
          { title: 'Editar conta' },
        ]}
      />

      {openFinanceInitialFlowData && (
        <PluggyConnect
          connectToken={openFinanceInitialFlowData.accessToken}
          onSuccess={handleInitialFlow}
          onError={handleErrorFlow}
          onClose={() => setOpenFinanceInitialFlowData(null)}
        />
      )}

      <Stack
        width="100%"
        height="calc(100% - 130px)"
        direction="row"
        gap="32px"
        overflow="auto"
      >
        {isLoading && (
          <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Stack>
        )}

        {!isLoading && (
          <Stack
            width="100%"
            maxWidth="800px"
            padding="32px"
            height="100%"
            gap="32px"
            direction="column"
          >
            <Stack
              width="100%"
              justifyContent="space-between"
              direction="row"
              alignItems="center"
              alignContent="center"
            >
              <Typography fontWeight="700" fontSize="32px" color="#1E1E1E">
                Editar conta
              </Typography>
            </Stack>

            <Typography fontWeight="700" fontSize="24px" color="#1E1E1E">
              Dados da conta
            </Typography>

            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              gap="24px"
            >
              <BaseSelectInput
                label="Pessoa Física ou Jurídica"
                fullWidth
                labelBackground="#F4F8FA"
                disabled={openFinance}
                options={[
                  {
                    label: 'Pessoa física',
                    value: BankAccountPersonTypeEnum.FISICA,
                  },
                  {
                    label: 'Pessoa jurídica',
                    value: BankAccountPersonTypeEnum.JURIDICA,
                  },
                ]}
                {...updateForm.fields.personType}
              />
              <BaseSelectInput
                label="Instituição financeira"
                labelBackground="#F4F8FA"
                disabled={
                  openFinance ||
                  !!(
                    accountType &&
                    [
                      BankAccountTypeEnum.CAIXINHA,
                      BankAccountTypeEnum.OUTROS,
                    ]?.includes(accountType)
                  )
                }
                options={banks.map((bank) => {
                  return {
                    label: bank.name,
                    value: bank.code,
                  }
                })}
                fullWidth
                showSearchInput
                {...updateForm.fields.bankNumber}
              />
            </Stack>

            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              gap="24px"
            >
              <BaseInput
                label="Agência"
                fullWidth
                disabled={
                  openFinance ||
                  !!(
                    accountType &&
                    [
                      BankAccountTypeEnum.CAIXINHA,
                      BankAccountTypeEnum.OUTROS,
                    ]?.includes(accountType)
                  )
                }
                onBlurEvent={handleBlurAgency}
                labelBackground="#F4F8FA"
                {...updateForm.fields.agencyNumber}
              />
              <BaseInput
                label="Número da conta"
                fullWidth
                disabled={
                  openFinance ||
                  !!(
                    accountType &&
                    [
                      BankAccountTypeEnum.CAIXINHA,
                      BankAccountTypeEnum.OUTROS,
                    ]?.includes(accountType)
                  )
                }
                onBlurEvent={handleBlurAcountNumber}
                labelBackground="#F4F8FA"
                {...updateForm.fields.accountNumber}
              />
            </Stack>

            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              gap="24px"
            >
              <BaseInput
                label="Início dos lançamentos (DD/MM/AAAA)"
                fullWidth
                labelBackground="#F4F8FA"
                type="date"
                value={transactionCommencementDate}
                disabled
                error=""
                setState={(e) => setTransactionCommencementDate(e)}
              />
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                gap="24px"
              >
                <BaseSelectInput
                  label="Moeda"
                  labelBackground="#F4F8FA"
                  disabled={openFinance}
                  options={currencies.map((currency) => {
                    return {
                      label: currency.name,
                      value: currency.symbol,
                    }
                  })}
                  value={currency ?? 'R$'}
                  fullWidth
                  error=""
                  setState={() => null}
                />
                <BaseInputMoney
                  label={openFinance ? 'Saldo atual' : 'Saldo inicial'}
                  disabled
                  fullWidth
                  labelBackground="#F4F8FA"
                  value={amount}
                  error=""
                  setState={(e) => setAmount(e)}
                />
              </Stack>
            </Stack>

            <Stack width="calc(50% - 12px)" direction="row">
              <BaseInput
                label="Nome da Conta"
                fullWidth
                labelBackground="#F4F8FA"
                {...updateForm.fields.name}
              />
            </Stack>

            {/* Conexão OpenFinance */}
            {accountType &&
              ![
                BankAccountTypeEnum.CAIXINHA,
                BankAccountTypeEnum.OUTROS,
              ]?.includes(accountType) &&
              ![undefined, 'Basic']?.includes(GetCompanyData()?.plan?.name) && (
                <Stack width="100%" gap="24px">
                  <Typography fontWeight="700" fontSize="24px" color="#1E1E1E">
                    Conexão Open Finance
                  </Typography>
                  <Button
                    fullWidth={false}
                    variant="outlined"
                    onClick={handleOpenPluggyConsentModal}
                    sx={{
                      padding: '12px 24px 12px 16px',
                      gap: '8px',
                      width: openFinance ? '150px' : '240px',
                      height: '40px',
                    }}
                  >
                    {openFinance ? 'Desconectar' : 'Configurar conexão'}
                  </Button>
                </Stack>
              )}

            <Stack width="calc(50% - 12px)" gap="24px">
              <Typography fontWeight="700" fontSize="24px" color="#1E1E1E">
                Vinculo de conta contábil
              </Typography>

              {isLoadingCC && (
                <>
                  <Stack
                    width="100%"
                    height="56px"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Stack>
                </>
              )}
              {!isLoadingCC && (
                <BaseInputAutocomplete
                  label="Conta contábil"
                  fullWidth
                  labelBackground="#F4F8FA"
                  options={ledgerAccounts}
                  inputVariant="bordered"
                  {...updateForm.fields.ledgerAccount}
                />
              )}

              <Stack direction="row" gap="16px">
                <BaseInputMoney
                  label="Saldo inicial"
                  fullWidth
                  labelBackground="#F4F8FA"
                  value={ledgerAccountBalance}
                  error=""
                  setState={(e) => {
                    setLedgerAccountBalance(e)
                    console.log('e', e)
                  }}
                />
                <BaseTooltip
                  backgroundColor="transparent"
                  message="Se o saldo da conta contábil for credor, colocar o sinal de menos na frente. Se for devedor, deixar positivo o valor informado"
                />
              </Stack>

              <BaseInput
                label="Início dos lançamentos (DD/MM/AAAA)"
                fullWidth
                labelBackground="#F4F8FA"
                type="date"
                value={ledgerAccountStartDate}
                error=""
                setState={(e) => setLedgerAccountStartDate(e)}
              />
            </Stack>

            <Stack
              width="100%"
              justifyContent="flex-end"
              alignItems="center"
              direction="row"
              gap="32px"
            >
              <Button
                fullWidth={false}
                onClick={handleCancel}
                variant="text"
                sx={{
                  padding: '12px 24px 12px 16px',
                  gap: '8px',
                  width: '110px',
                  height: '40px',
                }}
              >
                Cancelar
              </Button>
              <Button
                fullWidth={false}
                variant="contained"
                onClick={handleValidate}
                sx={{
                  padding: '12px 24px 12px 16px',
                  gap: '8px',
                  width: '100px',
                  height: '40px',
                }}
              >
                Salvar
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
