import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { Stack, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/system'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { UserFullContext } from 'src/context/context-user'
import { useSidebarContext } from 'src/shared/context/sidebar-provider'
import {
  getFormattedUserName,
  getInitials,
} from 'src/utils/stringFormatterUtils'
import OpenIMini from '../../assets/logo/logo-mini-yellow.png'
import OpenI from '../../assets/logo/logo-white-yellow.png'
import { BaseInputSingleSelect } from '../base-component/base-input-single-select'
import { ToastFullContext } from '../base-component/base-snackbar'
import { useSideBarData } from './data'
import { CircleBox, CustomItemList, IconBox, UserAvatar } from './styles'

const drawerWidth = 225

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '24px',
  gap: '16px',
  backgroundColor: '#1E3466',
  border: '0px, 1px, 0px, 0px',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  position: 'relative',
}))

export const Sidebar: React.FC = () => {
  const navigate = useNavigate()
  const sideBarData = useSideBarData()
  const { GetUserData, Logout, CompanyInfo } = UserFullContext()
  const { ShowToast } = ToastFullContext()
  const user = GetUserData()
  const { opened, setOpened } = useSidebarContext()
  const [currentMenuOpen, setCurrentMenuOpen] = useState(sideBarData[0].title)
  const { companyId: currentCompanyId } = useParams()

  const handleDrawerOpen = () => {
    setOpened(true)
  }

  const handleDrawerClose = () => {
    setOpened(false)
  }

  const onMenuClick = (menuItem: string, path: string) => {
    setCurrentMenuOpen(menuItem)
    navigate(path)
  }

  const handleLogout = () => {
    Logout()
    ShowToast('success', 'Sessão encerrada')
    navigate('/')
  }

  const handleChangeCurrentCompany = (
    companyId: string,
    companyName: string,
    plan?: {
      id: string
      name: string
    },
  ) => {
    CompanyInfo({ id: companyId, name: companyName, plan })
    const currentPath = window.location.pathname

    const newPath = currentPath.replace(currentCompanyId as string, companyId)

    navigate(newPath)
  }

  return (
    <Stack width="auto" maxWidth="225px" zIndex={100} id="sidebar-id">
      <Stack
        sx={{
          backgroundColor: '#1E3466',
          zIndex: 100,
        }}
        padding="24px"
        width="60px"
        height="calc(100vh - 48px)"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Stack>
          <Stack
            width="100%"
            direction="column"
            gap="16px"
            justifyContent="center"
            alignItems="center"
            marginBottom="16px"
          >
            <img src={OpenIMini} alt="Openi" />
            <Stack flexDirection="row" position="relative">
              <UserAvatar>{getInitials(user.name)}</UserAvatar>
              <CircleBox
                onClick={handleDrawerOpen}
                style={{
                  zIndex: 10,
                }}
              >
                <ChevronRightIcon />
              </CircleBox>
            </Stack>
          </Stack>
          <Divider color="#BFBFBF" sx={{ marginBottom: '16px' }} />
          <Stack
            width="100%"
            direction="column"
            gap="16px"
            justifyContent="center"
            alignItems="center"
            marginBottom="16px"
            sx={{ cursor: 'pointer' }}
          >
            <Stack
              flexDirection="row"
              position="relative"
              onClick={handleDrawerOpen}
            >
              <IconBox
                isActive={false}
                key={`change-company`}
                onClick={handleDrawerOpen}
              >
                <SwapHorizOutlinedIcon />
              </IconBox>
            </Stack>
          </Stack>
          <Divider color="#BFBFBF" sx={{ marginBottom: '16px' }} />
        </Stack>
        <Stack
          direction="column"
          gap="16px"
          justifyContent="flex-start"
          alignItems="center"
          marginBottom="16px"
          height="100%"
        >
          {sideBarData.map((item, index) => {
            return (
              <IconBox
                isActive={currentMenuOpen === item.title}
                key={`minified-${index}`}
                onClick={() => onMenuClick(item.title, item.path)}
              >
                {item.icon}
              </IconBox>
            )
          })}
        </Stack>
        <Stack
          width="100%"
          direction="column"
          gap="16px"
          justifyContent="center"
          alignItems="center"
          marginBottom="16px"
        >
          <IconBox key={`minified-exit`} onClick={handleLogout}>
            <LogoutIcon />
          </IconBox>
        </Stack>
      </Stack>

      {opened && (
        <CircleBox
          style={{
            display: 'flex',
            position: 'absolute',
            left: '205px',
            top: '105px',
            transform: 'translateY(-50%)',
            zIndex: 999,
          }}
          onClick={handleDrawerClose}
        >
          <ChevronLeftIcon />
        </CircleBox>
      )}

      <Drawer
        sx={{
          width: opened ? drawerWidth : 60,
          zIndex: 998,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: opened ? drawerWidth : 60,
            transition: 'width 0.2s ease-in-out',
            backgroundColor: '#1E3466',
          },
          '& .MuiDrawer-docked': {
            width: '60px',
          },
        }}
        variant="persistent"
        anchor="left"
        open={opened}
      >
        <DrawerHeader>
          <img
            src={OpenI}
            alt="Openi"
            style={{ height: '33px', width: '119px' }}
          />
          <Stack flexDirection="row" position="relative" gap="16px">
            <UserAvatar>{getInitials(user.name)}</UserAvatar>
            <Stack>
              <Typography
                color="#ffffff"
                display="flex"
                width="99px"
                height="21px"
                fontWeight="400"
                fontSize="14px"
              >
                Bem vindo(a),
              </Typography>
              <Typography
                color="#ffffff"
                display="flex"
                width="auto"
                height="21px"
                fontWeight="700"
                fontSize="14px"
              >
                {getFormattedUserName(user.name)}
              </Typography>
            </Stack>
          </Stack>
        </DrawerHeader>

        <Stack width="100%" justifyContent="center" alignItems="center">
          <Stack
            width="90%"
            sx={{
              color: 'white !important',
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '100px',
                border: 'none',
                borderColor: 'transparent',
                color: 'white',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                border: 'none',
                color: 'white !important',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                border: 'none',
                color: 'white !important',
              },
              '& span': {
                color: 'white !important',
              },
            }}
          >
            <BaseInputSingleSelect
              placeholder=""
              options={(user?.companies ?? [])
                ?.filter((f) => !!f?.plan)
                ?.map((company) => {
                  return {
                    label: company.name as string,
                    value: company.id as string,
                  }
                })}
              showLabel={false}
              value={currentCompanyId}
              onSelect={(e) => {
                const tempCompanyName =
                  (user?.companies ?? [])?.find((f) => f.id === e)?.name ?? ''

                const plan =
                  (user?.companies ?? [])?.find((f) => f.id === e)?.plan ??
                  undefined
                return handleChangeCurrentCompany(e, tempCompanyName, plan)
              }}
              marginBottom={0}
              marginTop={0}
            />
          </Stack>
        </Stack>

        <Stack width="100%" justifyContent="center" alignItems="center">
          <Stack width="176px">
            <Divider color="#ffffff" />
          </Stack>
        </Stack>

        <List
          sx={{
            display: 'flex',
            padding: '24px',
            backgroundColor: '#1E3466',
            border: '0px, 1px, 0px, 0px',
            gap: '24px',
            flexDirection: 'column',
          }}
        >
          {sideBarData.map((item, index) => {
            return (
              <CustomItemList
                key={`opened${index}`}
                isActive={currentMenuOpen === item.title}
                onClick={() => onMenuClick(item.title, item.path)}
              >
                <ListItemText
                  sx={{ fontSize: '12px !important' }}
                  primary={item.title}
                />
              </CustomItemList>
            )
          })}
        </List>
        <Stack position="absolute" bottom="0px" padding="24px" gap="24px">
          <Stack borderTop="1px solid white" />
          <CustomItemList key={`opened-exit`} onClick={handleLogout}>
            <ListItemText
              sx={{ fontSize: '12px !important' }}
              primary={'Sair'}
            />
          </CustomItemList>
        </Stack>
      </Drawer>
    </Stack>
  )
}
