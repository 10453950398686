import { Stack, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { ClassificationRuleModal } from 'src/component/modal/classificationRules/modal-create-classification-rule'
import { EditClassificationRuleModal } from 'src/component/modal/classificationRules/modal-edit-classification-rule'
import { ReplicateClassificationRuleModal } from 'src/component/modal/classificationRules/modal-replicate-classification-rules-success'
import { ClassificationRuleViewModal } from 'src/component/modal/classificationRules/modal-view-classification-rule'
import { SelectCompanyModal } from 'src/component/modal/company/modal-select-company'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { SimpleTextModal } from 'src/component/modal/simple-modal-text'
import { ColumnsCommon } from 'src/component/table/table'
import {
  ICompanyClassificationRule,
  TCompanyInfoResponse,
} from 'src/service/service-company'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'
import { ListCompanyClassificationRulesUseCase } from 'src/usecase/company/usecase-company-classification-rules-list'
import { CreateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-create-classification-rule'
import { DeleteClassificationRuleUseCase } from 'src/usecase/company/usecase-company-delete-classification-rule'
import { ListCompanyUseCase } from 'src/usecase/company/usecase-company-list'
import { ReplyCompanyClassificationRuleUseCase } from 'src/usecase/company/usecase-company-reply-classification-rule'
import { UpdateAccountClassificationRuleUseCase } from 'src/usecase/company/usecase-company-update-classification-rule'
import { LedgerAccountType } from '../classifier/useClassifier'

export function useClassificationRules(
  listClassificationRulesUseCase: ListCompanyClassificationRulesUseCase,
  createClassificationRuleUseCase: CreateAccountClassificationRuleUseCase,
  deleteClassificationRuleUseCase: DeleteClassificationRuleUseCase,
  updateClassificationRuleUseCase: UpdateAccountClassificationRuleUseCase,
  listCompaniesUseCase: ListCompanyUseCase,
  replyCompanyClassificationRuleUseCase: ReplyCompanyClassificationRuleUseCase,
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase,
  companyId: string,
) {
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)
  const [classificationRules, setClassificationRules] = useState<
    TCompanyInfoResponse['classificationRules'][]
  >([])
  const [companies, setCompanies] = useState<
    TCompanyInfoResponse['companies'][]
  >([])
  const [selectedGridIds, setSelectedGridIds] = useState<string[]>([])

  const [ledgerAccounts, setLedgerAccounts] =
    useState<null | LedgerAccountType>()

  const handleOpenViewClassificationRuleModal = (
    item: ICompanyClassificationRule,
  ) => {
    ShowModal({
      content: <ClassificationRuleViewModal classificationRule={item} />,
      title: 'Regra de classificação',
      closeOnBackgroundClick: false,
    })
  }

  const handleListUserCompaniesUsecase = async () => {
    try {
      setIsLoading(true)
      const usecaseResult = await listCompaniesUseCase.handle({})
      setIsLoading(false)

      if (usecaseResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
        )
      }

      const companiesData =
        usecaseResult.getValue() as TCompanyInfoResponse['companies'][]

      setCompanies(companiesData)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao efetuar o login. Tente novamente mais tarde.',
      )
    }
  }

  const handleDeleteAccount = async (classificationIds: string[]) => {
    try {
      setIsLoading(true)

      await Promise.all(
        classificationIds.map(async (classificationId) => {
          const accountsResult = await deleteClassificationRuleUseCase.handle({
            data: {
              companyId: companyId as string,
              classificationRuleId: classificationId,
            },
          })

          if (accountsResult.isFailure) {
            ShowToast(
              'error',
              'Ocorreu um erro ao excluír a(s) regras(s). Tente novamente mais tarde.',
            )
          }

          return classificationId
        }),
      )

      setIsLoading(false)
      ShowToast('success', 'Regra(s) de classificação excluída(s) com sucesso')
      handleListClassificationRulesUsecase()
      HideModal()
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Ocorreu um erro ao excluír a(s) regras(s). Tente novamente mais tarde.',
      )
    }
  }

  const handleDeleteAccountModal = (classificationIds: string[]) => {
    ShowModal({
      content: (
        <SimpleTextModal
          firstButtonText="Cancelar"
          secondButtonText="Sim, excluir"
          firstButtonAction={() => HideModal()}
          secondButtonAction={() => handleDeleteAccount(classificationIds)}
          text="A exclusão não terão ação retroativa. Deseja continuar?"
          height={120}
          width={380}
        />
      ),
      title: 'Excluir regra de classificação',
      closeButton: false,
    })
  }

  const handleExecuteReplyRules = async (companies: string[]) => {
    const filteredRules = classificationRules.filter(
      (f) => !!selectedGridIds.find((item) => item === f.id),
    )

    if (!filteredRules) {
      return
    }
    const result = await replyCompanyClassificationRuleUseCase.handle({
      classificationRules: filteredRules.map((rule) => {
        return { id: rule.id }
      }),
      companies: companies.map((company) => {
        return { id: company }
      }),
      companyId,
    })

    if (result.isFailure) {
      return ShowToast(
        'error',
        'Erro ao replicar as regras para as empresas selecionadas. Tente novamente mais tarde.',
      )
    }

    const newRules = result.getValue()
    const totalImported =
      newRules?.filter((f) => f.status === 'CREATED')?.length ?? 0
    const totalReplaced =
      newRules?.filter((f) => f.status === 'UPDATED')?.length ?? 0

    HideModal()
    ShowModal({
      content: (
        <ReplicateClassificationRuleModal
          totalImported={totalImported}
          totalReplaced={totalReplaced}
        />
      ),
      title: '',
      closeButton: false,
    })
  }

  const handleReplayClassificationRulesModal = () => {
    const filteredRules = classificationRules.filter(
      (f) => !!selectedGridIds.find((item) => item === f.id),
    )

    if (!filteredRules) {
      return
    }

    const filteredCompanies = companies.filter((f) => f.id !== companyId)

    ShowModal({
      content: (
        <SelectCompanyModal
          companies={filteredCompanies}
          handle={handleExecuteReplyRules}
          confirmationText="Ao replicar uma regra com variáveis idênticas já existente na empresa, a mais antiga será substituída pela nova."
          confirmationStep={true}
          confirmationButtonText="Replicar"
        />
      ),
      title: 'Replicar regras de classificação',
      closeButton: false,
    })
  }

  const handleListClassificationRulesUsecase = async () => {
    try {
      setIsLoading(true)
      const classificationRulesResult =
        await listClassificationRulesUseCase.handle({
          companyId: companyId as string,
          query: searchQuery,
        })
      setIsLoading(false)

      if (classificationRulesResult.isFailure) {
        ShowToast(
          'error',
          'Erro ao recuperar dados das regras de classificação relacionadas a empresas',
        )
      }

      const classificationRulesData =
        classificationRulesResult.getValue() as TCompanyInfoResponse['classificationRules'][]

      setClassificationRules(classificationRulesData)
    } catch (error) {
      setIsLoading(false)
      ShowToast(
        'error',
        'Erro ao recuperar dados das regras de classificação relacionadas a empresas',
      )
    }
  }

  const handleListClassificationRules = async () => {
    try {
      handleListClassificationRulesUsecase()
    } catch (error) {
      ShowToast(
        'error',
        'Erro ao recuperar dados das regras de classificação relacionadas a empresas',
      )
    }
  }

  const handleEditClassificationRuleModal = (
    classificationRule: ICompanyClassificationRule,
  ) => {
    HideModal()
    ShowModal({
      content: (
        <EditClassificationRuleModal
          classificationRule={classificationRule}
          companyId={companyId}
          refetchAction={handleListClassificationRulesUsecase}
          updateClassificationRuleUseCase={updateClassificationRuleUseCase}
          ledgerAccounts={ledgerAccounts as LedgerAccountType}
        />
      ),
      title: 'Regra de classificação',
      closeButton: false,
    })
  }

  const handleConfirmEditClassificationRuleModal = (
    classificationRule: ICompanyClassificationRule,
  ) => {
    ShowModal({
      content: (
        <SimpleTextModal
          firstButtonText="Cancelar"
          secondButtonText="Sim, editar"
          firstButtonAction={() => HideModal()}
          secondButtonAction={() =>
            handleEditClassificationRuleModal(classificationRule)
          }
          text="As edições não terão alteração retroativa, ou seja, serão aplicadas a partir dessa alteração. Deseja continuar?"
          height={170}
          width={370}
        />
      ),
      title: 'Editar regra de classificação',
      closeButton: false,
    })
  }

  const columns: GridColDef[] = [
    {
      ...ColumnsCommon,
      flex: 0.3,
      ...{
        field: 'accountingChartItem.code',
        headerName: 'Código',
      },
      renderCell: (params) => `${params.row.accountingChartItem?.code || ''}`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      align: 'left',
      ...{
        field: 'name',
        headerName: 'Nome',
      },
      renderCell: (params) => `${params.row.name}`,
    },
    {
      ...ColumnsCommon,
      flex: 0.5,
      minWidth: 320,
      ...{
        field: 'id',
        headerName: 'Ações',
        align: 'left',
        renderCell: (params) => (
          <Stack
            direction="row"
            width="calc(100% - 24px)"
            justifyContent="space-between"
            alignItems="center"
            paddingRight="24px"
            height="100%"
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              height="100%"
              sx={{
                cursor: 'pointer',
                color: '#4865CC',
              }}
              onClick={(e) => {
                e.preventDefault()
                handleOpenViewClassificationRuleModal(params.row)
              }}
            >
              <Typography>Visualizar</Typography>
            </Stack>

            <Stack
              justifyContent="center"
              height="100%"
              alignItems="center"
              sx={{ cursor: 'pointer', color: '#4865CC' }}
              onClick={(e) => {
                e.preventDefault()
                handleConfirmEditClassificationRuleModal(params.row)
              }}
            >
              <Typography>Editar</Typography>
            </Stack>
            <Stack
              justifyContent="center"
              height="100%"
              alignItems="center"
              sx={{ cursor: 'pointer', color: '#4865CC' }}
              onClick={(e) => {
                e.preventDefault()
                handleDeleteAccountModal([params.row.id])
              }}
            >
              <Typography>Excluir</Typography>
            </Stack>
          </Stack>
        ),
      },
    },
  ]

  const handleCreateClassificationRule = () => {
    ShowModal({
      content: (
        <ClassificationRuleModal
          companyId={companyId}
          createClassificationRuleUseCase={createClassificationRuleUseCase}
          refetchAction={handleListClassificationRulesUsecase}
          ledgerAccounts={ledgerAccounts as LedgerAccountType}
        />
      ),
      title: 'Regras de classificação',
      closeOnBackgroundClick: false,
    })
  }

  const handleGeLedgerAccounts = async () => {
    try {
      const ledgerAccountResult = await listLedgerAccountsUseCase.handle({
        companyId: companyId as string,
        query: '',
      })

      if (ledgerAccountResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar as contas contábeis. Tente novamente mais tarde.',
        )
        return
      }
      const ledgerAccountData = ledgerAccountResult.getValue()
      setLedgerAccounts(ledgerAccountData)
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas contábeis. Tente novamente mais tarde.',
      )
      console.error(error)
    }
  }

  useEffect(() => {
    handleGeLedgerAccounts()
  }, [])

  return {
    searchQuery,
    columns,
    classificationRules,
    isLoading,
    selectedGridIds,
    setSearchQuery,
    setSelectedGridIds,
    setIsLoading,
    setClassificationRules,
    handleListClassificationRules,
    handleCreateClassificationRule,
    handleListUserCompaniesUsecase,
    handleDeleteAccountModal,
    handleReplayClassificationRulesModal,
  }
}
