import { Button, CircularProgress, Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { format } from 'date-fns'
import { useState } from 'react'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { ColumnsCommon, Table } from 'src/component/table/table'
import { TCompanyAccountEntryCreate } from 'src/service/service-company'
import {
  fixSpecialCharacters,
  formatCpfOrCnpj,
  formatMoney,
  parseToNumber,
} from 'src/shared/util/formatter/formatter-utils'

export type TModalImportEntriesType = {
  name: string
  cnpj: string
  description: string
  historic: string
  value: string
  date: string
  debit: string
  credit: string
  financialCategory: string
  bankAccountId: string
  bankAccountName: string
}

export const CompanyAccountingEntriesCreateMassModal: React.FC<{
  data: TModalImportEntriesType[]
  handle: (data: TCompanyAccountEntryCreate[]) => Promise<void>
  bankAccountOptions: {
    label: string
    value: string
    icon: JSX.Element
  }[]
}> = ({ data, handle, bankAccountOptions }) => {
  const { HideModal } = ModalFullContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  console.log(bankAccountOptions, data)

  const handleCreate = async () => {
    setIsLoading(true)
    const finalData: TCompanyAccountEntryCreate[] = data.map((dataItem) => {
      const containsDecimal = dataItem.value?.includes(',')
      const decimalPlaces = containsDecimal
        ? dataItem.value.split(',')[1].length
        : 0

      const amount = Math.abs(
        Number(dataItem.value?.replace(/[^\d-]/g, '')) /
          (containsDecimal ? (decimalPlaces === 1 ? 10 : 100) : 1),
      )
      const validatedNumber =
        Number(dataItem.value?.replace(/[^\d-]/g, '')) /
        (containsDecimal ? (decimalPlaces === 1 ? 10 : 100) : 1)
      const localDate = new Date(dataItem.date)
      return {
        date: localDate.toUTCString(),
        value: amount.toString(),
        method: validatedNumber <= 0 ? 'DEBITO' : 'CREDITO',
        bankTransferMethod: validatedNumber <= 0 ? 'DEBITO' : 'CREDITO',
        credit: dataItem.credit,
        debit: dataItem.debit,
        description: dataItem.description,
        financialCategory: dataItem.financialCategory,
        historic: dataItem.historic,
        vendor: undefined,
        bankAccountFromId:
          bankAccountOptions?.find(
            (f) =>
              f.label?.toLowerCase() ===
              dataItem?.bankAccountName?.toLowerCase(),
          )?.value ?? undefined,
      }
    })

    await handle(finalData)
    setIsLoading(false)
  }

  const columns: GridColDef[] = [
    {
      ...ColumnsCommon,
      flex: 1.5,
      ...{
        field: 'name',
        minWidth: 220,
        headerName: 'Nome do cliente',
      },
      renderCell: (params) => `${fixSpecialCharacters(params.row.name)}`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      ...{ field: 'cnpj', headerName: 'CNPJ' },
      minWidth: 220,
      renderCell: (params) => (
        <>{formatCpfOrCnpj(params.row.cnpj.toString().replace(/\D/g, ''))}</>
      ),
    },
    {
      ...ColumnsCommon,
      flex: 1.5,
      minWidth: 220,
      ...{ field: 'description', headerName: 'Descrição' },
      renderCell: (params) => `${fixSpecialCharacters(params.row.description)}`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      minWidth: 220,
      ...{ field: 'historic', headerName: 'Historico' },
      renderCell: (params) => `${params.row.historic}`,
    },
    {
      ...ColumnsCommon,
      flex: 0.5,
      minWidth: 180,
      ...{ field: 'value', headerName: 'Valor' },
      renderCell: (params) => `${formatMoney(parseToNumber(params.row.value))}`,
    },
    {
      ...ColumnsCommon,
      flex: 0.5,
      minWidth: 180,
      ...{ field: 'date', headerName: 'Data' },
      renderCell: (params) =>
        `${format(new Date(params.row.date), 'dd/MM/yyyy')}`,
    },
    {
      ...ColumnsCommon,
      flex: 1.5,
      minWidth: 180,
      ...{ field: 'credit', headerName: 'Crédito' },
      renderCell: (params) => `${params.row.credit}`,
    },
    {
      ...ColumnsCommon,
      flex: 0.5,
      minWidth: 180,
      ...{ field: 'debit', headerName: 'Débito' },
      renderCell: (params) => `${params.row.debit}`,
    },
    {
      ...ColumnsCommon,
      flex: 0.5,
      minWidth: 220,
      ...{ field: 'financialCategory', headerName: 'Categoria Financeira' },
      renderCell: (params) => `${params.row.financialCategory}`,
    },
    {
      ...ColumnsCommon,
      flex: 1,
      minWidth: 220,
      ...{ field: 'bankAccountName', headerName: 'Conta bancária' },
      renderCell: (params) =>
        `${bankAccountOptions?.find((f) => f.label?.toLowerCase() === params.row?.bankAccountName?.toLowerCase())?.label ?? 'Sem Conta'}`,
    },
  ]

  return (
    <Stack
      width="50vw"
      maxHeight="595px"
      gap="24px"
      justifyContent="space-between"
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Table
        columns={columns}
        rows={data?.map((item, index) => {
          return {
            ...item,
            id: index,
          }
        })}
        tableStyle={{
          flex: 1,
        }}
        tableContainerStyle={{
          height: '100%',
          maxHeight: '500px',
          overflowY: 'auto',
          overflowX: 'auto',
        }}
        loadMore={() => null}
        totalPages={1}
        totalRegisters={data.length}
        currentPage={1}
        allowSelectAll={false}
        loading={false}
        checkboxSelection={false}
        showFilterButton={false}
        showSearchButton={false}
        showNoResultsOverlay={false}
      />
      <Stack
        width="100%"
        justifyContent="flex-end"
        gap="24px"
        alignItems="center"
        direction="row"
      >
        <Button onClick={HideModal} variant="outlined" sx={{ width: '140px' }}>
          Cancelar
        </Button>

        <Button
          onClick={handleCreate}
          variant="contained"
          sx={{ width: '140px' }}
        >
          {!isLoading ? 'Criar' : <CircularProgress />}
        </Button>
      </Stack>
    </Stack>
  )
}
