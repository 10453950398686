import { Button, CircularProgress, Stack } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { BaseInput } from 'src/component/base-component/base-input'
import { BaseInputAutocomplete } from 'src/component/base-component/base-input-autocomplete'
import { BaseInputMoney } from 'src/component/base-component/base-input-money'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'

export const SyncSystemicAccountsModal: React.FC<{
  handle: (
    selectedAccount: string,
    currentSelectedAccountBalance: string,
    currentSelectedAccountStartDate: string,
  ) => void
  companyId: string
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase
}> = ({ handle, companyId, listLedgerAccountsUseCase }) => {
  const [internalControlLedgerAccount, setInternalControlLedgerAccount] =
    useState<string>('')
  const [internalControlStartDate, setInternalControlStartDate] =
    useState<string>(format(new Date(), 'yyyy-MM-dd'))
  const [internalControlBalance, setInternalControlBalance] =
    useState<string>('0')
  const [ledgerAccounts, setLedgerAccounts] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { HideModal } = ModalFullContext()
  const { ShowToast } = ToastFullContext()

  const handleClose = () => {
    HideModal()
  }

  const handleSync = useCallback(() => {
    if (!internalControlLedgerAccount) {
      return
    }
    handle &&
      handle(
        internalControlLedgerAccount,
        internalControlBalance,
        internalControlStartDate,
      )
  }, [
    internalControlLedgerAccount,
    internalControlBalance,
    internalControlStartDate,
  ])

  const handleGeLedgerAccounts = async () => {
    try {
      setIsLoading(true)
      const ledgerAccountResult = await listLedgerAccountsUseCase.handle({
        companyId: companyId as string,
        query: '',
      })

      if (ledgerAccountResult.isFailure) {
        ShowToast(
          'error',
          'Ocorreu um erro ao buscar as contas contábeis. Tente novamente mais tarde.',
        )
      }

      const ledgerAccountData = ledgerAccountResult.getValue()
      const combined = [
        ...new Set([
          ...(ledgerAccountData?.credtis?.ledger ?? []),
          ...(ledgerAccountData?.debits?.ledger ?? []),
        ]),
      ]
      setLedgerAccounts(combined)
      setIsLoading(false)
    } catch (error) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar as contas contábeis. Tente novamente mais tarde.',
      )
      console.error(error)
    }
  }

  useEffect(() => {
    handleGeLedgerAccounts()
  }, [])

  return (
    <Stack width="500px" height={'220px'} gap="16px">
      <Stack width="100%">
        {isLoading && (
          <>
            <Stack
              width="100%"
              height="56px"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Stack>
          </>
        )}
        {!isLoading && (
          <BaseInputAutocomplete
            options={ledgerAccounts}
            inputVariant="bordered"
            label="Conta contábil"
            error=""
            fullWidth
            setState={(e) => setInternalControlLedgerAccount(e)}
            value={internalControlLedgerAccount}
          />
        )}
      </Stack>
      <Stack width="100%" direction="row" gap="12px">
        <BaseInputMoney
          label="Saldo Inicial"
          fullWidth
          value={internalControlBalance}
          error=""
          setState={(e) => setInternalControlBalance(e)}
        />
        <BaseInput
          label="Iní. dos lançamentos"
          fullWidth
          type="date"
          value={internalControlStartDate}
          error=""
          setState={(e) => setInternalControlStartDate(e)}
        />
      </Stack>
      <Stack
        direction="row"
        gap="16px"
        marginTop="24px"
        justifyContent="end"
        alignItems="end"
      >
        <Button
          fullWidth={false}
          variant="outlined"
          onClick={() => handleClose()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Cancelar
        </Button>

        <Button
          fullWidth={false}
          variant={
            [null, undefined, ''].includes(internalControlLedgerAccount)
              ? 'outlined'
              : 'contained'
          }
          disabled={[null, undefined, ''].includes(
            internalControlLedgerAccount,
          )}
          onClick={() => handleSync()}
          sx={{
            padding: '12px 24px 12px 16px',
            gap: '8px',
            width: '120px',
            height: '40px',
          }}
        >
          Vincular
        </Button>
      </Stack>
    </Stack>
  )
}
