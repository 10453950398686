export enum SPECIAL_COMPANY {
  SOLAR_GRID = 'ace891ca-424a-410d-b9df-ae6f76a72797',
  TRANSFERO = 'ace891ca-424a-410d-b9dadf-ae6f76a727973',
}
export const specialFieldsByCompany = {
  [SPECIAL_COMPANY.SOLAR_GRID]: [
    { value: 'costCenter', label: 'Centro de Custo' },
    { value: 'paymentType', label: 'Tipo de Pagamento' },
    { value: 'paymentGroup', label: 'Grupo de Pagamento' },
    { value: 'project', label: 'Projeto' },
    { value: 'cashFlow', label: 'Fluxo de Caixa' },
  ],

  [SPECIAL_COMPANY.TRANSFERO]: [
    { value: 'costCenter', label: 'Centro de Custo' },
  ],
}

export const specialCompaniesList = [
  SPECIAL_COMPANY.SOLAR_GRID,
  SPECIAL_COMPANY.TRANSFERO,
]

export const allSpecialFieldsList = specialCompaniesList.reduce(
  (acc: { value: string; label: string }[], company) => [
    ...acc,
    ...specialFieldsByCompany[company as SPECIAL_COMPANY],
  ],
  [] as { value: string; label: string }[],
)
