import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BaseInternalHeader } from 'src/component/base-component/base-internal-header'
import { BaseMultipleSelectInput } from 'src/component/base-component/base-multiple-select'
import { BasePageTitle } from 'src/component/base-component/base-page-title'
import { ToastFullContext } from 'src/component/base-component/base-snackbar'
import { BaseTooltip } from 'src/component/base-component/base-tooltip'
import { ColorCircle } from 'src/component/base-component/colored-circle'
import {
  EntryFlowComponent,
  GroupedData,
} from 'src/component/conciliator/entry-flow-tab'
import { DefaultHeader } from 'src/component/header/header'
import { MenuDotIcon } from 'src/component/icons/menu-dot'
import { NotificationBellIcon } from 'src/component/icons/notification-bell'
import { ModalClassifierExport } from 'src/component/modal/classifier/modal-classifier-export'
import { ModalFullContext } from 'src/component/modal/modal-provider'
import { UserFullContext } from 'src/context/context-user'
import {
  CompanyAccountEntryConciliationStatusEnum,
  ICompanyAccountEntriesResponse,
} from 'src/service/service-company'
import { formatMoney } from 'src/shared/util/formatter/formatter-utils'
import { ListLedgerAccountsUseCase } from 'src/usecase/bank-account/usecase-account-get-ledger-accounts'
import { ListAccountingEntriesToConciliatorUseCase } from 'src/usecase/conciliator/usecase-accounting-entries-conciliator-list'
import { generateXLS } from 'src/utils/generateXLS'
import BlueHeader from '../../assets/background/header-blue.png'
import { useConciliator } from './useConciliator'
import { ConciliateAccountingEntriesUseCase } from 'src/usecase/conciliator/usecase-accounting-entries-conciliate'
import { UpdateAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-update'
import { ConciliatorIcon } from 'src/component/icons/conciliator'
import { CreateManualAccountingEntriesUseCase } from 'src/usecase/classifier/usecase-accounting-entries-create'
import { ListUserCompaniesAccountsUseCase } from 'src/usecase/bank-account/usecase-user-companies-list'
import { useUserAccount } from 'src/page/systemic-accounts/useUserAccount'
import { extractBankName } from 'src/utils/normalizedBankLogos'
import { Logo } from 'src/component/base-component/base-logo-component'

interface ConciliatorPageProps {
  listLedgerAccountsUseCase: ListLedgerAccountsUseCase
  listAccountingEntriesToConciliatorUseCase: ListAccountingEntriesToConciliatorUseCase
  conciliateAccountingEntriesUseCase: ConciliateAccountingEntriesUseCase
  updateAccountingEntriesUseCase: UpdateAccountingEntriesUseCase
  createManualAccountingEntriesUseCase: CreateManualAccountingEntriesUseCase
  listUserCompaniesAccountsUseCase: ListUserCompaniesAccountsUseCase
}

type TLedgerAccount = {
  account: string
  type: 'credit' | 'debit'
}

const groupDebits = (data: ICompanyAccountEntriesResponse[]): GroupedData[] => {
  const grouped = data.reduce<Record<string, ICompanyAccountEntriesResponse[]>>(
    (acc, current) => {
      const debit = current.debit?.toLowerCase()
      if (!debit) return acc
      if (!acc[debit]) {
        acc[debit] = []
      }
      acc[debit].push(current)
      return acc
    },
    {},
  )

  const response = Object.keys(grouped).map((debit) => ({
    title: debit,
    data: grouped[debit],
  }))

  return response
}

const groupCredits = (
  data: ICompanyAccountEntriesResponse[],
): GroupedData[] => {
  const grouped = data.reduce<Record<string, ICompanyAccountEntriesResponse[]>>(
    (acc, current) => {
      const credit = current.credit?.toLowerCase()
      if (!credit) return acc
      if (!acc[credit]) {
        acc[credit] = []
      }
      acc[credit].push(current)
      return acc
    },
    {},
  )

  // Converte o objeto agrupado em um array no formato esperado
  return Object.keys(grouped).map((credit) => ({
    title: credit,
    data: grouped[credit],
  }))
}

export function ConciliatorList(props: ConciliatorPageProps) {
  const { ShowToast } = ToastFullContext()
  const { ShowModal, HideModal } = ModalFullContext()
  const isSmallScreen = useMediaQuery('(max-width: 1024px)')
  const { companyId } = useParams()
  const { GetCompanyData } = UserFullContext()
  const [ledgerAccounts, setLedgerAccounts] = useState<TLedgerAccount[]>([])
  const [creditEntries, setCreditEntries] = useState<GroupedData[]>([])
  const [debitEntries, setDebitEntries] = useState<GroupedData[]>([])
  const [selectedDebits, setSelectedDebits] = useState<string[]>([])
  const [selectedCredits, setSelectedCredits] = useState<string[]>([])
  const [residualBalance, setResidualBalance] = useState<number | null>(null)
  const [total, setTotal] = useState<number>(0)
  const [showResults, setShowResults] = useState<boolean>(false)

  const { handleListUserCompaniesAccounts, userCompaniesAccounts } =
    useUserAccount(props.listUserCompaniesAccountsUseCase)

  const bankAccountOptions = userCompaniesAccounts.map((item) => {
    return {
      label: item.name,
      value: item.id,
      icon: (
        <Logo
          name={extractBankName(item.bank)}
          width={32}
          height={32}
          logo={item.bankLogo}
        />
      ),
    }
  })

  const { isLoading, selectedStatus, setSelectedStatus, setIsLoading } =
    useConciliator(companyId as string)

  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const handleGeLedgerAccounts = async () => {
    setIsLoading(true)
    const ledgerAccountResult = await props.listLedgerAccountsUseCase.handle({
      companyId: companyId as string,
      query: '',
    })

    if (ledgerAccountResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao buscar a contas bancária. Tente novamente mais tarde.',
      )
    }

    const ledgerAccountData = ledgerAccountResult.getValue()
    console.log(ledgerAccountData)

    const mapAndFormatCredits = ledgerAccountData?.credit?.ledger
      ?.filter((f) => ![null, undefined, ''].includes(f))
      .map((item) => {
        return {
          account: item,
          type: 'credit',
        }
      }) as TLedgerAccount[]
    const mapAndFormatDebits = ledgerAccountData?.debit?.ledger
      ?.filter((f) => ![null, undefined, ''].includes(f))
      ?.map((item) => {
        return {
          account: item,
          type: 'debit',
        }
      }) as TLedgerAccount[]
    const combinedAccounts = [...mapAndFormatDebits, ...mapAndFormatCredits]
    const uniqueAccounts = combinedAccounts.filter(
      (account, index, self) =>
        index === self.findIndex((acc) => acc.account === account.account),
    )

    setLedgerAccounts(uniqueAccounts)
    setIsLoading(false)
  }

  const handleGetEntries = useCallback(async () => {
    if (selectedOptions?.length === 0) {
      return
    }
    setShowResults(false)
    if (selectedStatus?.length === 0) {
      return ShowToast('error', 'Pelo menos um status deve ser selecionado.')
    }
    setIsLoading(true)
    setSelectedCredits([])
    setSelectedDebits([])
    const entriesResult =
      await props.listAccountingEntriesToConciliatorUseCase.handle({
        companyId: companyId as string,
        ledgerAccount: selectedOptions,
        status: selectedStatus,
      })

    if (entriesResult.isFailure) {
      ShowToast(
        'error',
        'Ocorreu um erro ao listar os dados do conciliador. Tente novamente mais tarde.',
      )
    }
    const entries = entriesResult?.getValue()
    if (entries?.debits) {
      const grouped = groupDebits(entries?.debits)
      setDebitEntries(grouped)
    }

    if (entries?.credits) {
      setCreditEntries(groupCredits(entries?.credits) ?? [])
    }
    setTotal(entries?.total ?? 0)
    setShowResults(true)
    setIsLoading(false)
  }, [selectedStatus, selectedOptions])

  useEffect(() => {
    handleGeLedgerAccounts()
    handleListUserCompaniesAccounts([companyId as string])
  }, [companyId])

  useEffect(() => {
    handleGetEntries()
  }, [selectedStatus, selectedOptions])

  useEffect(() => {
    if (selectedCredits?.length === 0 && selectedDebits.length === 0) {
      setResidualBalance(null)
      return
    }
    const totalCredits = creditEntries.reduce((total, entry) => {
      return (
        total +
        entry.data.reduce((sum, item) => {
          return selectedCredits.includes(item.id)
            ? sum + parseFloat(item.value)
            : sum
        }, 0)
      )
    }, 0)

    const totalDebits = debitEntries.reduce((total, entry) => {
      return (
        total +
        entry.data.reduce((sum, item) => {
          return selectedDebits.includes(item.id)
            ? sum + parseFloat(item.value)
            : sum
        }, 0)
      )
    }, 0)

    const balance = totalCredits - totalDebits
    setResidualBalance(balance)
  }, [selectedCredits, selectedDebits, creditEntries, debitEntries])

  const ExportOptions = useMemo(() => {
    const normalizeOptions = [
      { name: 'Bling', premium: false },
      { name: 'DBCorp', premium: true },
    ]?.map((option) => {
      return {
        label: option.name,
        value: option.name,
        component: (
          <Stack direction="row" alignItems="center" padding="0 20px">
            <Typography marginLeft="15px" fontSize={14} fontWeight={400}>
              {option.name}
            </Typography>
            {option.premium && (
              <Stack
                alignItems="center"
                justifyContent="center"
                bgcolor="#FCD24C"
                borderRadius="16px"
                padding="8px 16px"
                marginLeft="10px"
              >
                <Typography fontSize="12px" fontWeight={700} color="#1E3466">
                  Premium
                </Typography>
              </Stack>
            )}
          </Stack>
        ),
      }
    })

    return normalizeOptions
  }, [])

  const handleExport = async (type: string) => {
    if (['excel', 'xls_cred', 'xls_deb'].includes(type)) {
      const columns = [
        { header: 'Data', dataKey: 'date' },
        { header: 'Valor', dataKey: 'value' },
        { header: 'Histórico', dataKey: 'history' },
        { header: 'Conta Contábil', dataKey: 'ledgerAccount' },
        { header: 'Tipo', dataKey: 'type' },
      ]

      const debitData: any[] = []
      const creditData: any[] = []

      if (['excel', 'xls_deb'].includes(type)) {
        await Promise.all(
          debitEntries.map((item) => {
            const ledgerAccount = item.title
            item.data.map((itemData) => {
              const date = format(itemData.bankTransferDate, 'dd/MM/yyyy HH:mm')
              const value = formatMoney(Number(itemData.value))
              let history = ''
              if (
                itemData.bankTransferPartie?.name ||
                itemData.bankTransferPartie?.document ||
                itemData.bankTransferPartie?.type
              ) {
                if (itemData?.bankTransferPartie?.name) {
                  history += ` ${itemData?.bankTransferPartie?.name}`
                }
                if (
                  ![null, undefined, ''].includes(itemData?.bankTransferType)
                ) {
                  history += ` ${itemData?.bankTransferType}`
                }
                if (itemData.bankTransferPartie?.type) {
                  history += ` ${
                    itemData.bankTransferPartie.type === 'PESSOA_NATURAL'
                      ? 'PF'
                      : 'PJ'
                  }`
                }
                if (itemData.bankTransferPartie.document) {
                  if (itemData.bankTransferPartie.document.length > 11) {
                    const formattedDocument =
                      itemData.bankTransferPartie.document
                    history += ` ${formattedDocument}`
                  } else {
                    const formattedDocument =
                      itemData.bankTransferPartie.document
                    history += ` ${formattedDocument}`
                  }
                }
              } else {
                if (
                  ![null, undefined, ''].includes(itemData.bankTransferType)
                ) {
                  history += ` ${itemData.bankTransferType}`
                }
                if (!itemData.bankTransferType) {
                  if (itemData.description) {
                    history += ` ${itemData.description}`
                  } else {
                    history += ` ${itemData.method}`
                  }
                }
              }
              debitData.push([date, value, history, ledgerAccount, 'Débito'])
              return itemData
            })
            return item
          }),
        )
      }

      if (['excel', 'xls_cred'].includes(type)) {
        await Promise.all(
          creditEntries.map((item) => {
            const ledgerAccount = item.title
            item.data.map((itemData) => {
              const date = format(itemData.bankTransferDate, 'dd/MM/yyyy HH:mm')
              const value = formatMoney(Number(itemData.value))
              let history = ''
              if (
                itemData.bankTransferPartie?.name ||
                itemData.bankTransferPartie?.document ||
                itemData.bankTransferPartie?.type
              ) {
                if (itemData.bankTransferPartie?.name) {
                  history += ` ${itemData.bankTransferPartie?.name}`
                }
                if (
                  ![null, undefined, ''].includes(itemData.bankTransferType)
                ) {
                  history += ` ${itemData.bankTransferType}`
                }
                if (itemData.bankTransferPartie.type) {
                  history += ` ${
                    itemData.bankTransferPartie.type === 'PESSOA_NATURAL'
                      ? 'PF'
                      : 'PJ'
                  }`
                }
                if (itemData.bankTransferPartie.document) {
                  if (itemData.bankTransferPartie.document.length > 11) {
                    const formattedDocument =
                      itemData.bankTransferPartie.document
                    history += ` ${formattedDocument}`
                  } else {
                    const formattedDocument =
                      itemData.bankTransferPartie.document
                    history += ` ${formattedDocument}`
                  }
                }
              } else {
                if (
                  ![null, undefined, ''].includes(itemData.bankTransferType)
                ) {
                  history += ` ${itemData.bankTransferType}`
                }
                if (!itemData.bankTransferType) {
                  if (itemData.description) {
                    history += ` ${itemData.description}`
                  } else {
                    history += ` ${itemData.method}`
                  }
                }
              }
              creditData.push([date, value, history, ledgerAccount, 'Crédito'])
              return itemData
            })
            return item
          }),
        )
      }

      const sheets = [
        {
          sheetName: 'Débitos - Créditos',
          columns: columns.map((column) => column.header),
          data: [...debitData, ...creditData],
        },
      ]

      await generateXLS(
        sheets,
        `Relatorio-Conciliador-${format(new Date(), 'dd-MM-yyyy')}.xlsx`,
      )
      return
    }

    if (type === 'erp') {
      ShowToast('warning', 'Função não implementada.')
    }
  }

  const handleConciliate = async () => {
    setIsLoading(true)

    const normalizedLedgerAccounts = selectedOptions.map((account) =>
      account.toLowerCase(),
    )

    // Não devemos permitir conciliação entre contas contábeis diferentes
    const isValidLedgerAccount = normalizedLedgerAccounts.every(
      (account) => account === normalizedLedgerAccounts[0],
    )

    if (!isValidLedgerAccount) {
      setIsLoading(false)
      return ShowToast(
        'error',
        'Não é permitida a conciliação entre contas contábeis diferentes.',
      )
    }

    try {
      const classificationResult =
        await props.conciliateAccountingEntriesUseCase.handle({
          data: {
            companyId: companyId as string,
            entryId: [...selectedCredits, ...selectedDebits],
            ledgerAccount: selectedOptions,
          },
        })

      if (classificationResult.isFailure) {
        setIsLoading(false)
        return ShowToast('error', 'Falha ao executar a conciliação.')
      }

      ShowToast('success', 'Conciliação realizada com sucesso.')
      handleGetEntries()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      ShowToast('error', 'Falha ao executar a conciliação.')
    }
  }

  const handleConciliateManual = async () => {
    if (selectedCredits?.length === 0 || selectedDebits.length === 0) {
      return ShowToast(
        'error',
        'Ao menos um registro de débito e crédito deve ser selecionado.',
      )
    }
    handleConciliate()
  }

  const handleDespise = async (selectedItens: string[]) => {
    if (selectedItens?.length === 0) {
      return ShowToast('error', 'Nenhum registro selecionado.')
    }

    setIsLoading(true)

    try {
      await Promise.all(
        selectedItens.map(async (item) => {
          await props.updateAccountingEntriesUseCase.handle({
            companyId: companyId as string,
            entryId: item,
            field: 'status',
            value: CompanyAccountEntryConciliationStatusEnum.DESPISED,
          })
          return item
        }),
      )
      handleGetEntries()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleDesconciliate = async (selectedItens: string[]) => {
    if (selectedItens?.length === 0) {
      return ShowToast('error', 'Nenhum registro selecionado.')
    }

    setIsLoading(true)

    try {
      await Promise.all(
        selectedItens.map(async (item) => {
          await props.updateAccountingEntriesUseCase.handle({
            companyId: companyId as string,
            entryId: item,
            field: 'status',
            value: CompanyAccountEntryConciliationStatusEnum.NOT_CONCILIATED,
          })
          return item
        }),
      )
      handleGetEntries()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleRecover = async (selectedItens: string[]) => {
    if (selectedItens?.length === 0) {
      return ShowToast('error', 'Nenhum registro selecionado.')
    }

    setIsLoading(true)

    try {
      await Promise.all(
        selectedItens.map(async (item) => {
          await props.updateAccountingEntriesUseCase.handle({
            companyId: companyId as string,
            entryId: item,
            field: 'status',
            value: CompanyAccountEntryConciliationStatusEnum.NOT_CONCILIATED,
          })
          return item
        }),
      )
      handleGetEntries()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleEditEntry = async (
    entryData: Partial<ICompanyAccountEntriesResponse>,
  ) => {
    setIsLoading(true)
    try {
      await props.updateAccountingEntriesUseCase.handle({
        companyId: companyId as string,
        entryId: entryData.id as string,
        multipleFields: [
          {
            field: 'credit',
            value: entryData.credit as string,
          },
          {
            field: 'debit',
            value: entryData.debit as string,
          },
          {
            field: 'value',
            value: entryData.value as string,
          },
          {
            field: 'financialCategory',
            value: entryData.financialCategory as string,
          },
        ],
      })
      handleGetEntries()
      HideModal()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <Stack
      width="100%"
      height="100%"
      flexGrow={1}
      sx={{ backgroundColor: '#F4F8FA' }}
    >
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        breadcumbItems={[
          {
            title: GetCompanyData().name as string,
            navigateTo: `/company/${GetCompanyData().id}`,
          },
          {
            title: 'Conciliador',
          },
        ]}
        buttons={
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                width: '40px',
                height: '40px',
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '15px',
                width: '40px',
                height: '40px',
              }}
              onClick={() => console.log('empty')}
            >
              <MenuDotIcon />
            </IconButton>
          </Stack>
        }
      />

      <Stack
        flex={1}
        flexGrow={1}
        height="calc(100% - 130px)"
        padding="32px"
        gap="24px"
        overflow="hidden"
      >
        <Stack
          width="100%"
          height="100%"
          gap="24px"
          overflow="auto"
          sx={{ overflowX: 'hidden' }}
        >
          <BaseInternalHeader
            leftComponent={<BasePageTitle color="#1E3466" text="Conciliador" />}
          />
          <Stack
            flexDirection="row"
            width="calc(100% - 64px)"
            alignItems="center"
            gap="24px"
          >
            <Stack width="280px">
              <BaseMultipleSelectInput
                options={ledgerAccounts?.map((item) => {
                  return {
                    label: item.account,
                    value: item.account,
                  }
                })}
                setState={setSelectedOptions}
                error=""
                value={selectedOptions}
                label="Conta contábil"
              />
            </Stack>
            <Button
              variant="contained"
              sx={{ width: '92px', height: '40px', marginTop: '17px' }}
              onClick={handleGetEntries}
            >
              Buscar
            </Button>
            <Stack
              width="100%"
              alignItems="center"
              justifyContent="flex-end"
              direction="row"
              gap="24px"
            >
              <Button
                variant="outlined"
                sx={{
                  width: isSmallScreen ? '120px' : '260px',
                  height: '40px',
                }}
                onClick={handleConciliate}
              >
                {isSmallScreen ? 'Conciliar' : 'Conciliar automaticamente'}
              </Button>
              <Button
                variant="outlined"
                sx={{ width: '128px', height: '40px' }}
                onClick={() =>
                  ShowModal({
                    content: (
                      <ModalClassifierExport
                        handle={(e) => handleExport(e)}
                        options={ExportOptions}
                        showSubTypes
                        subTypes={[
                          {
                            label: 'Créditos',
                            value: 'xls_cred',
                          },
                          {
                            label: 'Débitos',
                            value: 'xls_deb',
                          },
                        ]}
                      />
                    ),
                    title: 'Exportar',
                  })
                }
              >
                Exportar
              </Button>
            </Stack>
          </Stack>

          <Stack
            width="100%"
            paddingRight="12px"
            height="100%"
            justifyContent="flex-start"
          >
            {selectedOptions.length === 0 && (
              <Stack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  fontWeight="600"
                  fontSize="16px"
                  color="#000000"
                  textAlign="center"
                >
                  Selecione uma ou mais contas contábeis para visualizar os
                  lançamentos
                </Typography>
              </Stack>
            )}
            {isLoading && (
              <Stack
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Stack>
            )}
            {!isLoading && selectedOptions.length > 0 && showResults && (
              <Stack
                width="100%"
                height="100%"
                direction="column"
                gap="24px"
                justifyContent="flex-start"
              >
                <Stack
                  height="40px"
                  width="100%"
                  gap="24px"
                  direction="row"
                  justifyContent="flex-start"
                >
                  {[
                    {
                      label: 'Conciliados',
                      value:
                        CompanyAccountEntryConciliationStatusEnum.CONCILIATED,
                      color: '#03B575',
                    },
                    {
                      label: 'Parcialmente conciliados',
                      value:
                        CompanyAccountEntryConciliationStatusEnum.CONCILIATED_PARTIAL,
                      color: '#E7B92D',
                      tooltip:
                        'Lançamentos com parte da conciliação programada para extratos seguintes ou anteriores',
                    },
                    {
                      label: 'Não conciliados',
                      value:
                        CompanyAccountEntryConciliationStatusEnum.NOT_CONCILIATED,
                      color: '#DB2D25',
                    },
                    {
                      label: 'Desprezados',
                      value: CompanyAccountEntryConciliationStatusEnum.DESPISED,
                      color: '#B9BFC7',
                    },
                  ].map(({ label, value, color, tooltip }) => (
                    <Stack
                      key={label}
                      gap={isSmallScreen ? '6px' : '8px'}
                      width="auto"
                      justifyContent="flex-start"
                      alignItems="center"
                      direction="row"
                    >
                      <Checkbox
                        sx={{
                          color: '#4865CC',
                          '&.Mui-checked': {
                            color: '#4865CC',
                          },
                        }}
                        value={value}
                        checked={selectedStatus.includes(value)}
                        onChange={(e, checked) => {
                          e.stopPropagation()
                          if (!checked) {
                            if (selectedStatus.length === 1) {
                              return ShowToast(
                                'error',
                                'Pelo menos um status deve ser selecionado.',
                              )
                            }

                            return setSelectedStatus([
                              ...selectedStatus?.filter((f) => f !== value),
                            ])
                          }
                          return setSelectedStatus([...selectedStatus, value])
                        }}
                      />
                      <ColorCircle color={color} width={8} height={8} />
                      <Typography
                        fontWeight={400}
                        fontSize={isSmallScreen ? '12px' : '14px'}
                        lineHeight="19.07px"
                      >
                        {label}
                      </Typography>
                      {tooltip && <BaseTooltip message={tooltip} key={label} />}
                    </Stack>
                  ))}
                </Stack>

                <Stack
                  id="panel-stack"
                  width="calc(100% - 24px)"
                  flexDirection={isSmallScreen ? 'column' : 'row'}
                  gap="24px"
                  alignItems="center"
                  sx={{ overflowX: 'hidden' }}
                  whiteSpace="nowrap"
                >
                  <EntryFlowComponent
                    title="Débitos"
                    data={debitEntries}
                    type="debit"
                    key="debits"
                    selectedOptions={selectedDebits}
                    setSelectedOptions={setSelectedDebits}
                    handleDespise={handleDespise}
                    handleRecover={handleRecover}
                    handleDesconciliate={handleDesconciliate}
                    handleEditEntry={handleEditEntry}
                    createManualAccountingEntriesUseCase={
                      props.createManualAccountingEntriesUseCase
                    }
                    accountsOptions={bankAccountOptions}
                    listLedgerAccountsUseCase={props.listLedgerAccountsUseCase}
                    oppositeSelectedOptions={selectedCredits}
                  />
                  <Stack
                    sx={{ cursor: 'pointer' }}
                    onClick={handleConciliateManual}
                  >
                    <ConciliatorIcon />
                  </Stack>
                  <EntryFlowComponent
                    title="Créditos"
                    data={creditEntries}
                    type="credit"
                    key="credits"
                    selectedOptions={selectedCredits}
                    setSelectedOptions={setSelectedCredits}
                    handleDespise={handleDespise}
                    handleRecover={handleRecover}
                    handleDesconciliate={handleDesconciliate}
                    handleEditEntry={handleEditEntry}
                    createManualAccountingEntriesUseCase={
                      props.createManualAccountingEntriesUseCase
                    }
                    accountsOptions={bankAccountOptions}
                    listLedgerAccountsUseCase={props.listLedgerAccountsUseCase}
                    oppositeSelectedOptions={selectedDebits}
                  />
                </Stack>

                <Stack width="calc(100% - 24px)" gap="16px">
                  {residualBalance !== null && (
                    <Typography
                      fontWeight="400"
                      fontSize="16px"
                      lineHeight="21.79px"
                      color={residualBalance > 0 ? '#03B575' : 'red'}
                    >
                      {`A diferença entre os valores selecionados é de ${formatMoney(residualBalance)}`}
                    </Typography>
                  )}
                  <Stack
                    justifyContent="center"
                    alignItems="flex-start"
                    borderRadius="16px"
                    width="100%"
                    height="65px"
                    sx={{ backgroundColor: '#FFFFFF' }}
                  >
                    {total !== null && (
                      <Typography
                        marginLeft="16px"
                        fontWeight="700"
                        fontSize="24px"
                        lineHeight="32.69px"
                        color="#1E3466"
                      >
                        Saldo: {formatMoney(total < 0 ? total * -1 : total)}{' '}
                        {total < 0 ? 'a crédito' : 'a débito'}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
