import { LedgerAccountType } from 'src/page/classifier/useClassifier'
import { AccountApiRemoteService } from 'src/service/service-account'
import { Result } from 'src/shared/protocol/protoco-result'
import { IUsecase } from 'src/shared/protocol/protocol-usecase'

export class ListLedgerAccountsUseCase
  implements IUsecase<{ companyId: string; query: string }, LedgerAccountType>
{
  constructor(private userApi: AccountApiRemoteService) {}

  public async handle({
    companyId,
    query,
  }: {
    companyId: string
    query: string
  }): Promise<Result<LedgerAccountType>> {
    try {
      const response = await this.userApi.getLedgerAccounts(companyId, query)

      if (response.isFailure) {
        return Result.fail(
          new Error('Erro ao recuperar dados das contas contábeis'),
        )
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(
          new Error('Erro ao recuperar dados das contas contábeis'),
        )
      }

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }
}
